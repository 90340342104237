// firebase.js

import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyBUrQtJw73tq53TlGsRUbPoYQZm5dCMJK8",
    authDomain: "natural-product-28266.firebaseapp.com",
    databaseURL: "https://natural-product-28266-default-rtdb.firebaseio.com",
    projectId: "natural-product-28266",
    storageBucket: "natural-product-28266.appspot.com",
    messagingSenderId: "833430077464",
    appId: "1:833430077464:web:b11d9751094c1e9fc6436d",
    measurementId: "G-9VM174V52N"
  };

  const app = initializeApp(firebaseConfig);
  const database = getDatabase(app);
  
  export default database;