import './App.css';
import database from '../../Config/firebase';
import { ref, onValue } from 'firebase/database';
import { useEffect, useState } from 'react';
import LocationSvg from "../../img/Location.svg"
import Call from "../../img/call.svg"

function App() {
  const [data, setData] = useState(null);

  const handleClick = () => {
    window.open("https://maps.app.goo.gl/k4imsLDm5XJwTKPy5", '_blank');
  };

  const mrprogrammer = () => {
    window.open("https://www.mrprogramer.info", '_blank');

  }


  useEffect(() => {
    const onDataChange = (snapshot) => {
      const newData = snapshot.val();
      if (newData) {
        setData(newData);
      }
    };

    const dataRef = ref(database, 'MrShop/Category');
    onValue(dataRef, onDataChange);
    return () => {
      onValue(dataRef, onDataChange);
    };
  }, []);

  return (
    <div className="App">

      <p className='title'>Sri Vetri Food Products</p>

      {data && (
        <div>
          <div className="image-container">
            {Object.entries(data).map(([key, value]) => (
              <div key={key} className="col-12 col-md-6 col-lg-4 mb-3">

          
                <a href={`products/${value.category }`} target='_blank' style={{ textDecoration: 'none' }}>
                <div className="image-item">
                  <img src={value.imageUrl} className="img_item" />
                  <div className="image-text">
                    {`${value.category}`}
                  </div>
                </div>
                </a>


              </div>
            ))}
          </div>
        </div>
      )}


      <div>

        <img className='location_img' src={LocationSvg}/>


        <p className='vist_our_shop'>Visit Our Shop</p>
        <p className='address'>Goverment Girls High School Back Side<br />Kanjikoil Road, Nadupalayam<br />Cithode, Erode - 638102</p>

        <div className='direction' onClick={handleClick}>
          <p className='direction_text'>Get Direction</p>
        </div>

        <div>

          <div className='map_container'>
            <iframe className="map" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3911.1849404745944!2d77.657659!3d11.39411!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTHCsDIzJzM4LjgiTiA3N8KwMzknMjcuNiJF!5e0!3m2!1sen!2sin!4v1703929557033!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

          </div>

          <p className='mrprogrammer' onClick={mrprogrammer}>Mr.Programmer</p>
        </div>

      </div>


      <div class="icon-container">
      <a class="call-link" href="tel:+919578192232">
   <div class="icon">

    <img src={Call}/>
   </div>

   </a>
  </div>
    </div>
  );
}

export default App;
