// App.js
import React from 'react';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import App from './Screens/Category/App';
import Products from './Screens/Product/Product';


const Main = () => {
  return (

    <BrowserRouter>
    
      <Routes>
        <Route path="/" element={<App />}/>
        <Route path="products/:id"element={ <Products />} />

      </Routes>
    </BrowserRouter>

  );
};

export default Main;
