// App.js
import React from 'react';
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import { ref, onValue } from 'firebase/database';
import { useEffect, useState } from 'react';
import database from '../../Config/firebase';
import './Product.css';
import Call from "../../img/call.svg"



const Products = () => {
  const matchedItems = 0;
  const category = useParams()
  const [data, setData] = useState(null);


  const mrprogrammer = () => {
    window.open("https://www.mrprogramer.info", '_blank');

  }

  
  useEffect(() => {
    const onDataChange = (snapshot) => {
      const newData = snapshot.val();
      if (newData) {
        setData(newData);
      }
    };

    const dataRef = ref(database, 'MrShop/Product');
    onValue(dataRef, onDataChange);
    return () => {
      onValue(dataRef, onDataChange);
    };
  }, []);

  return (


    <div className='App'>
      <div className="App">
       <p className='title'>Sri Vetri Food Products</p>
       </div>

    {data && (
      <div>
        <div className="image-container-grid">
          {Object.entries(data).map(([key, value]) => (            
            (value.category === category.id) && (
              <div key={key} >
                <div className="image-item-grid">
                  <img src={value.imageUrl} className="img_item" alt={value.productName} />


                  <div className='text-container'>


                  <div className="image-text-black">
                    {`${value.productName}`}
                  </div>
                  <div className="image-text">
                    {`₹ ${value.price}`}
                  </div>
                    </div>


                
                </div>
              </div>
            )
          ))}
        </div>
      </div>
    )}

<p className='mrprogrammer' onClick={mrprogrammer}>Mr.Programmer</p>

<div class="icon-container">
      <a class="call-link" href="tel:+919578192232">
   <div class="icon">

    <img src={Call}/>
   </div>

   </a>
  </div>
  </div>
  

  );
};

export default Products;
